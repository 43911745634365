import React, { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";

import Inner from "../../layout/inner";

import { useLocation } from "react-router-dom";

import { useParams } from "react-router-dom";

import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { getShop, getShopIdle } from "../../modules/shop/actions";
import { selectorShop } from "../../modules/shop/selector";
import { setIdSale } from "../../modules/sale/actions";
import {
  getUrlImage,
  prepareUtmLink,
  targetMetrick,
} from "../../constants/config";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper/modules";
import PostShop from "../../components/postShop";

const ContentShop = (props) => {
  const result = useSelector(selectorShop);
  const related = useSelector((state) => state.shop.relatedShops);
  const categoriesById = useSelector((state) => state.initData.categoriesById);
  const [salesSwiper, setSalesSwiper] = useState(null);

  const slider = useRef(null);

  const dispatch = useDispatch();

  const { showSearch, setShowSearch } = props;

  const [showAlpha, setShowAlpha] = useState(false);
  const [showPromocode, setShowPromocode] = useState(false);
  const [copyPromocode, setCopyPromocode] = useState(false);
  const statusShop = useSelector((state) => state.shop.status);

  let location = useLocation();

  useEffect(() => {
    setShowPromocode(false);
  }, [location]);

  const [data, setData] = useState({
    preview: "",
    logo: "",
    title: "",
    description: "",
    percentSale: null,
    idSale: null,
    titleSale: "",
    promocodeSale: "",
    infoSale: "",
    percentSaleAlpha: null,
    previewSaleAlpha: null,
    titleSaleAlpha: "",
    descriptionSaleAlpha: "",
    linkSaleAlpha: null,
  });

  useEffect(() => {
    if (statusShop == "SUCCESS") {
      setData({
        ...data,
        ...result,
      });
    }
  }, [statusShop]);

  useEffect(() => {
    setShowSearch(false);
  }, []);

  const handleOpenPromocode = (e) => {
    e.preventDefault();

    if (data.idSale) {
      dispatch(setIdSale({ id: data.idSale }));
    } else {
      setShowPromocode(!showPromocode);
    }

    targetMetrick("Клик на акцию в магазине " + data.title);
  };

  const handleClosePromocode = (e) => {
    e.preventDefault();

    setShowPromocode(false);
  };

  const handleOpenAlpha = (e) => {
    e.preventDefault();

    if (data.linkSaleAlpha) {
      window.open(data.linkSaleAlpha, "_blank");
    } else {
      setShowAlpha(!showAlpha);
    }
  };

  const handleCloseAlpha = (e) => {
    e.preventDefault();

    setShowAlpha(false);
  };

  const handleCopyPromocode = (e) => {
    e.preventDefault();

    if (!copyPromocode) {
      setCopyPromocode(true);
      navigator.clipboard.writeText("podeli");

      targetMetrick("Копировать промокод. Акция. " + data.title);
    }
  };

  return (
    <div className="shop _single">
      <div className="shop__hero">
        <div
          className="shop__preview"
          style={{ backgroundImage: "url(" + data.preview + ")" }}
        >
          {data.logo && (
            <div className="shop__logo">
              <img src={data.logo} />
            </div>
          )}
        </div>
        <div className="shop__common">
          <div className="shop__title">{data.title}</div>
          <div className="shop__description">
            <ReactMarkdown
              linkTarget="_blank"
              children={data.description}
            ></ReactMarkdown>
          </div>
          {window.innerWidth > 1023 &&
            (result.Web_store || result.Retail_store_web) && (
              <div className="shop__buttons">
                {result.Web_store && (
                  <a
                    href={prepareUtmLink(
                      result.Web_store,
                      result.Hide_web_store_utm
                    )}
                    onClick={(e) => {
                      targetMetrick(
                        "Переход в интернет-магазин " + result.title
                      );
                    }}
                    target="_blank"
                    className="button _default" rel="noreferrer"
                  >
                    {result.Web_store_title || "Интернет-магазин"}
                  </a>
                )}

                {result.Retail_store_web && (
                  <a
                    href={prepareUtmLink(
                      result.Retail_store_web,
                      result.Hide_retail_store_utm
                    )}
                    onClick={(e) => {
                      targetMetrick(
                        "Переход в розничные магазины " + result.title
                      );
                    }}
                    target="_blank"
                    className={`button _default ${
                      (result.Web_store && "_gray") || ""
                    }`} rel="noreferrer"
                  >
                    {result.Retail_store_title || "Розничные магазины"}
                  </a>
                )}
              </div>
            )}
        </div>
      </div>
      <div className="shop__content">
        {(data.saleTitleInner || data.titleSaleAlpha) && (
          <>
            <div className="shop__sales">
              <div className="shop__sales-buttons">
                <div
                  className={`shop__sales-item ${
                    window.innerWidth > 1023 && showPromocode ? "_active" : ""
                  }`}
                >
                  {data.saleTitleInner &&
                    (data.titleSale ||
                      data.saleTitleOnGrayGroup ||
                      data.saleSubTitleOnGrayGroup) && (
                      <a
                        href="#"
                        onClick={handleOpenPromocode}
                        className="button _sale"
                      >
                        <span className="button__icon">
                          <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-promocode.svg" />
                        </span>
                        <span className="button__content">
                          {(data.titleSale || data.saleTitleOnGrayGroup) && (
                            <span className="button__title">
                              {(data.saleTitleOnGrayGroup && (
                                <ReactMarkdown>
                                  {data.saleTitleOnGrayGroup}
                                </ReactMarkdown>
                              )) ||
                                data.titleSale}
                              {/* -{data.percentSale}{data.percentSymbol} по промокоду <b>Promocode</b> */}
                            </span>
                          )}
                          {data.saleSubTitleOnGrayGroup && (
                            <span className="button__description">
                              {data.saleSubTitleOnGrayGroup}
                              {/* Во всех магазинах {data.title} */}
                            </span>
                          )}
                        </span>
                      </a>
                    )}
                  <div className={`shop__sales-item-content`}>
                    <div className="promocode">
                      {data && (
                        <>
                          <div
                            className="promocode__title"
                            dangerouslySetInnerHTML={{
                              __html: data.saleTitleInner,
                            }}
                          ></div>
                          <div className="promocode__lead">
                            <ReactMarkdown linkTarget="_blank">
                              {data.infoSale}
                            </ReactMarkdown>
                          </div>
                          {data.promocodeSale && (
                            <div className="promocode__value">
                              {data.promocodeSale}
                              <a
                                href="#"
                                onClick={handleCopyPromocode}
                                className="promocode__value-copy"
                              >
                                <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-copy.svg" />
                              </a>
                            </div>
                          )}
                          <div className="promocode__info">
                            <ReactMarkdown linkTarget="_blank">
                              {result.Organizator}
                            </ReactMarkdown>
                          </div>
                          {(data.saleWebStore || data.saleRetailStore) && (
                            <div className="promocode__footer">
                              {data.saleWebStore && (
                                <a
                                  href={data.saleWebStore}
                                  onClick={(e) => {
                                    targetMetrick(
                                      "Акция." +
                                        data.saleTitleInner +
                                        ".Интернет-магазин"
                                    );
                                  }}
                                  className="button _default"
                                >
                                  {(data.Sale_Web_store_title &&
                                    data.Sale_Web_store_title) ||
                                    "Интернет-магазин"}
                                </a>
                              )}
                              {data.saleRetailStore && (
                                <a
                                  href={data.saleRetailStore}
                                  onClick={(e) => {
                                    targetMetrick(
                                      "Акция." +
                                        data.saleTitleInner +
                                        ".Розничные магазины"
                                    );
                                  }}
                                  className="button _default _gray"
                                >
                                  {(data.Sale_Retail_store_title &&
                                    data.Sale_Retail_store_title) ||
                                    "Розничные магазины"}
                                </a>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`shop__sales-item ${
                    window.innerWidth > 1023 && showAlpha ? "_active" : ""
                  }`}
                >
                  {(data.titleSaleAlpha ||
                    data.saleTitleAlphaOnGrayGroup ||
                    data.saleSubTitleAlphaOnGrayGroup) && (
                    <a
                      href="#"
                      onClick={handleOpenAlpha}
                      className="button _sale"
                    >
                      <span className="button__icon">
                        <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-alpha.svg" />
                      </span>
                      <span className="button__content">
                        {(data.saleTitleAlphaOnGrayGroup ||
                          data.titleSaleAlpha) && (
                          <span className="button__title">
                            {(data.saleTitleAlphaOnGrayGroup && (
                              <ReactMarkdown>
                                {data.saleTitleAlphaOnGrayGroup}
                              </ReactMarkdown>
                            )) ||
                              data.titleSaleAlpha}
                            {/* ещё -{data.percentSaleAlpha}% по Альфа-Карте*/}
                          </span>
                        )}
                        {data.saleSubTitleAlphaOnGrayGroup &&
                          data.saleSubTitleAlphaOnGrayGroup !== "" && (
                            <span className="button__description">
                              {data.saleSubTitleAlphaOnGrayGroup}
                              {/* Во всех магазинах {data.title} */}
                            </span>
                          )}
                      </span>
                    </a>
                  )}
                  <div className={`shop__sales-item-content`}>
                    <div className="alpha">
                      {data.previewSaleAlpha && (
                        <div className="alpha__img">
                          <img src={data.previewSaleAlpha} />
                        </div>
                      )}
                      <div className="alpha__title">{data.titleSaleAlpha}</div>
                      <div className="alpha__lead">
                        <ReactMarkdown linkTarget="_blank">
                          {data.descriptionSaleAlpha}
                        </ReactMarkdown>
                      </div>
                      <div className="alpha__buttons">
                        <a href="#" className="button _default _red">
                          Оформить карту
                        </a>
                        <a href="#" className="button _default _white">
                          Привязать карту
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data.percentSale &&
                data.percentSale > 0 &&
                data.percentSaleAlpha && (
                  <div className="shop__sales-description">
                    Скидки суммируются, подели максимально выгодно с приложением
                    Подели
                  </div>
                )}
            </div>
          </>
        )}
        <div className="shop__title">{data.title}</div>
        <div className="shop__description">
          <ReactMarkdown
            linkTarget="_blank"
            children={data.description}
          ></ReactMarkdown>
        </div>
      </div>
      <div
        className={`shop__alpha ${
          (window.innerWidth < 1023 && showAlpha && "_active") || ""
        }`}
      >
        <div className="alpha">
          <a href="#" onClick={handleCloseAlpha} className="shop__alpha-close">
            <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-close-small.svg" />
          </a>
          {data.previewSaleAlpha && (
            <div className="alpha__img">
              <img src={data.previewSaleAlpha} />
            </div>
          )}
          <div className="alpha__title">{data.titleSaleAlpha}</div>
          <div className="alpha__lead">
            <ReactMarkdown linkTarget="_blank">
              {data.descriptionSaleAlpha}
            </ReactMarkdown>
          </div>
          <div className="alpha__buttons">
            <a href="#" className="button _default _red">
              Оформить карту
            </a>
            <a href="#" className="button _default _white">
              Привязать карту
            </a>
          </div>
        </div>
      </div>
      <div
        className={`shop__promocode-alert ${(copyPromocode && "_show") || ""}`}
      >
        <span>Промокод скопирован</span>
      </div>
      <div
        className={`shop__promocode ${
          (window.innerWidth < 1023 && showPromocode && "_active") || ""
        }`}
      >
        <div
          className="shop__promocode__bg"
          onClick={handleClosePromocode}
        ></div>
        <div className="promocode">
          <a
            href="#"
            onClick={handleClosePromocode}
            className="shop__promocode-close"
          >
            <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-close-small.svg" />
          </a>
          {data && (
            <>
              <div
                className="promocode__title"
                dangerouslySetInnerHTML={{ __html: data.saleTitleInner }}
              ></div>
              <div className="promocode__lead">
                <ReactMarkdown linkTarget="_blank">
                  {data.infoSale}
                </ReactMarkdown>
              </div>
              {data.promocodeSale && (
                <div className="promocode__value">
                  {data.promocodeSale}
                  <a
                    href="#"
                    onClick={handleCopyPromocode}
                    className="promocode__value-copy"
                  >
                    <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-copy.svg" />
                  </a>
                </div>
              )}
              <div className="promocode__info">
                <ReactMarkdown linkTarget="_blank">
                  {result.Organizator}
                </ReactMarkdown>
              </div>
              <div className="promocode__footer">
                {data.saleWebStore && (
                  <a
                    href={data.saleWebStore}
                    onClick={(e) => {
                      targetMetrick(
                        "Акция." + data.saleTitleInner + ".Интернет-магазин"
                      );
                    }}
                    className="button _default"
                  >
                    {(data.Sale_Web_store_title && data.Sale_Web_store_title) ||
                      "Интернет-магазин"}
                  </a>
                )}
                {data.saleRetailStore && (
                  <a
                    href={data.saleRetailStore}
                    onClick={(e) => {
                      targetMetrick(
                        "Акция." + data.saleTitleInner + ".Розничные магазины"
                      );
                    }}
                    className="button _default _gray"
                  >
                    {(data.Sale_Retail_store_title &&
                      data.Sale_Retail_store_title) ||
                      "Розничные магазины"}
                  </a>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {result.related && related && (
        <div className="shop__related">
          <div className="category _default _related">
            <div className="category__header">
              <div className="category__title">В этой категории ищут</div>
            </div>
            <div ref={slider} className="category__slider">
              <Swiper
                slidesPerView={4}
                spaceBetween={24}
                navigation={true}
                modules={[Navigation]}
                onSwiper={(swiper) => {
                  setSalesSwiper(swiper);
                }}
              >
                {result.related.map((resultitem) => {
                  const item = related[resultitem.id];
                  if (!item) {
                    return;
                  }
                  const { id, attributes } = item;

                  const {
                    Title,
                    Preview,
                    Logo,
                    Sale,
                    Sale_card,
                    Preview_list,
                    Subtitle,
                    Show_percent_on_preview,
                  } = attributes;

                  let Percent = null;

                  let Percent_symbol = null;

                  let Percent_Symbol_Before = "-";

                  if (Sale_card) {
                    if (Sale_card.Percent) {
                      Percent = Sale_card.Percent;
                    }
                    if (Sale_card.Percent_symbol) {
                      Percent_symbol = Sale_card.Percent_symbol;
                    }
                    if (Sale_card.Percent_Symbol_Before) {
                      Percent_Symbol_Before = Sale_card.Percent_Symbol_Before;
                    }
                  } else {
                    if (
                      Sale &&
                      Sale.data &&
                      Sale.data.attributes &&
                      Sale.data.attributes.Sale_card
                    ) {
                      if (Sale.data.attributes.Sale_card.Percent) {
                        Percent = Sale.data.attributes.Sale_card.Percent;
                      }
                      if (Sale.data.attributes.Sale_card.Percent_symbol) {
                        Percent_symbol =
                          Sale.data.attributes.Sale_card.Percent_symbol;
                      }
                    }
                  }

                  return (
                    <SwiperSlide key={item.id} className="category__list-item">
                      <PostShop
                        link={`/shop/${item.id}`}
                        preview={
                          (Preview_list && getUrlImage(Preview_list)) ||
                          (Preview && getUrlImage(Preview)) ||
                          ""
                        }
                        logo={(Logo && getUrlImage(Logo)) || ""}
                        sale={
                          ((Show_percent_on_preview ||
                            Show_percent_on_preview == null) &&
                            Percent &&
                            Percent_Symbol_Before +
                              Percent +
                              ((Percent_symbol && Percent_symbol) || "%")) ||
                          null
                        }
                        title={Title}
                        subTitle={Subtitle}
                        attributes={attributes}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <button
                className="category__slider-prev"
                onClick={() => salesSwiper.slidePrev()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 17.3594L9.6652 13.2432C9.15327 12.7571 9.15327 11.9616 9.6652 11.4755L14 7.35938"
                    stroke="#A7ABAC"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button
                className="category__slider-next"
                onClick={() => salesSwiper.slideNext()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 17.3594L14.3348 13.2432C14.8467 12.7571 14.8467 11.9616 14.3348 11.4755L10 7.35938"
                    stroke="#A7ABAC"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Shop = (props) => {
  const params = useParams();

  const dispatch = useDispatch();

  const statusShop = useSelector((state) => state.shop.status);

  const isOnlineShop = useSelector((state) => state.shop.isOnlineShop);
  const isOfflineShop = useSelector((state) => state.shop.isOfflineShop);
  const result = useSelector(selectorShop);

  useEffect(() => {
    if (statusShop == "SUCCESS") {
      dispatch(getShopIdle());
    }
  }, [statusShop]);

  useEffect(() => {
    if (statusShop == "IDLE") {
      dispatch(
        getShop({
          id: params.id,
        })
      );
    }
  }, [params.id]);

  return (
    <Inner
      content={<ContentShop {...props} />}
      dopClass={"_shop"}
      footer={
        (window.innerWidth < 1023 &&
          (result.Web_store || result.Retail_store_web) && (
            <>
              {result.Web_store && (
                <a
                  href={prepareUtmLink(
                    result.Web_store,
                    result.Hide_web_store_utm
                  )}
                  onClick={(e) => {
                    targetMetrick("Переход в интернет-магазин " + result.title);
                  }}
                  target="_blank"
                  className="button _default" rel="noreferrer"
                >
                  {result.Web_store_title || "Интернет-магазин"}
                </a>
              )}
                 
              {result.Retail_store_web && (
                <a
                  href={prepareUtmLink(
                    result.Retail_store_web,
                    result.Hide_retail_store_utm
                  )}
                  onClick={(e) => {
                    targetMetrick(
                      "Переход в розничные магазины " + result.title
                    );
                  }}
                  target="_blank"
                  className={`button _default ${
                    (result.Web_store && "_gray") || ""
                  }`} rel="noreferrer"
                >
                  {result.Retail_store_title || "Розничные магазины"}
                </a>
              )}
            </>
          )) ||
        null
      }
    ></Inner>
  );
};

export default Shop;
