import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { targetMetrick } from "../../constants/config";
import { setIdSale } from "../../modules/sale/actions";
import { Link } from "react-router-dom";
import PostSaleContent from "./postSaleContent";
import './style.css';

const PostSale = (props) => {

    let refSalePreview = useRef(null);
    const { id, link, title, description, logo, price, type, preview, target, attributes } = props;

    let Web_store = null;
    let Retail_store_web = null;

    if(attributes && attributes.Sale_card){
        
        Web_store = attributes.Sale_card.Web_store;
        Retail_store_web = attributes.Sale_card.Retail_store_web;

    }

    const dispatch = useDispatch();

    /*const handleShowSale = (e) => {
        if(!link || link == '#'){
            e.preventDefault();
            dispatch(setIdSale({ id }));
        }
        history.push("#" + location.pathname + "?sale=" + id);
        targetMetrick(target && target || title);
    }*/

    useEffect(() => {
        if(refSalePreview && preview){
            refSalePreview.current.style.backgroundImage = 'url(' + preview + ')';
        }
    }, [preview])

    return (
      <>
      {(link && link !== "#")
        &&
        <a
          ref={refSalePreview}
          href={link}
          target="_blank"
          className="post _sale carousel-control" rel="noreferrer"
        >
          <PostSaleContent
            logo={logo}
            price={price}
            type={type}
            title={title}
            description={description}
          />
        </a>
        ||
        <Link ref={refSalePreview} to={"?sale=" + id} replace={true} className='post _sale carousel-control'>
          <PostSaleContent
            logo={logo}
            price={price}
            type={type}
            title={title}
            description={description}
          />
        </Link>
      }
      </>
    )
};

export default PostSale;