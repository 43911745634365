import {
  call,
  put,
  takeEvery,
  all,
  takeLatest,
  select,
} from "redux-saga/effects";

import { getReguest } from "../initData/api";

import {
  getShopsByFilter,
  getShopsByFilterSuccess,
  getShopsByFilterFailure,
  getShopsByFilterIdle,
} from "./actions";

import { filterCategory } from "../initData/selector";
import { selectConfig } from "../config/selector";

export function* getShopsByFilterSaga(payload) {
  try {
    const config = yield select(selectConfig);
    let {
      currentCity,
      activesTypes,
      activesCategory,
      categoriesById,
      categories,
    } = payload;
    if (!categories) {
      categories = yield select(filterCategory);
    }
    let routeDefault = "/api/shops?";

    // let populate = "fields[0]=Title";
    // populate = populate + "&fields[1]=Subtitle";
    // populate = populate + "&fields[2]=Show_percent_on_preview";
    // populate = populate + "&populate=Preview,Preview_list,Logo,Sort";
    // let populate = "fields[0]=Sale";
    // populate = populate + "&populate=Sale_card";
    // populate = populate + "&populate=Shops";

    // routeDefault = routeDefault + populate;

    if (activesTypes && activesTypes.length > 0) {
      // 1 Интернет магазин
      // 2 Розничный магазин
      activesTypes.map((id, index) => {
        if (id == 1) {
          routeDefault = routeDefault + 'filters[Web_store][$notContains]=""&';
        }
        if (id == 2) {
          routeDefault =
            routeDefault + 'filters[Retail_store_web][$notContains]=""&';
        }
      });
    }

    if (currentCity && currentCity.id !== "all") {
      routeDefault =
        routeDefault + "&filters[Cities][id][$in]=" + currentCity.id;
    }

    let categoriesIds = [];

    if (activesCategory && activesCategory.length > 0) {
      activesCategory.map((id) => {
        categoriesIds.push(id);
      });
    } else {
      if (categories && categories.length > 0) {
        categories.map((item) => {
          categoriesIds.push(item.id);
        });
      }
    }

    let shopsByFilter = yield all(
      categoriesIds.map((id, index) => {
        let route = routeDefault + "&filters[Categories][id][$in]=" + id;

        route =
          route +
          "&pagination[pageSize]=100&populate[Preview][fields][0]=url&populate[Logo][fields][0]=url&populate[Preview_list][fields][0]=url&populate[Sale_card][fields][0]=*";
        return call(getReguest, { route, id, ...config });
      })
    );

    yield put(getShopsByFilterSuccess(shopsByFilter));
  } catch (error) {
    console.log(error);
    yield put(getShopsByFilterFailure(error));
  }
}

export function* getShopsByFilterWatcher() {
  yield takeLatest(getShopsByFilter.toString(), (action) =>
    getShopsByFilterSaga(action.payload)
  );
}
