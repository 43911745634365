import { createSelector } from "reselect";
import {
  getUrlImage,
  catalogType,
  CATALOG_TYPES,
} from "../../constants/config";

const stateSaleData = (state) => state.sale.data;

export const selectorSale = createSelector(stateSaleData, (data) => {
  let selectedData = {};
  const Web_store_title =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Web_store_title;
  const App_store_title =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.App_store_title;
  const AM_store_title =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.AM_store_title;
  const AO_store_title =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.AO_store_title;
  const Web_store =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Web_store;
  const Retail_store_web =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Retail_store_web;
  const Retail_store_app =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Retail_store_app;
  const Retail_store_ao =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Retail_store_ao;
  const Retail_store_am =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Retail_store_am;
  const App_store =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.App_store;
  const AM_store =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.AM_store;
  const AO_store =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.AO_store;
  const Hide_web_store_utm =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Hide_web_store_utm;
  const Hide_app_store_utm =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Hide_app_store_utm;
  const Hide_am_store_utm =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Hide_am_store_utm;
  const Hide_ao_store_utm =
    data &&
    data.attributes &&
    data.attributes.Sale_card &&
    data.attributes.Sale_card.Hide_ao_store_utm;

  const im_title = {
    [CATALOG_TYPES.CATALOG]: Web_store_title,
    [CATALOG_TYPES.APP]: App_store_title || Web_store_title,
    [CATALOG_TYPES.AM]: AM_store_title || Web_store_title,
    [CATALOG_TYPES.AO]: AO_store_title || Web_store_title,
  }[catalogType];
  const im_url = {
    [CATALOG_TYPES.CATALOG]: Web_store,
    [CATALOG_TYPES.APP]: App_store || Web_store,
    [CATALOG_TYPES.AM]: AM_store || Web_store,
    [CATALOG_TYPES.AO]: AO_store || Web_store,
  }[catalogType];
  const im_utm_hide = {
    [CATALOG_TYPES.CATALOG]: Hide_web_store_utm,
    [CATALOG_TYPES.APP]: Hide_app_store_utm || Hide_web_store_utm,
    [CATALOG_TYPES.AM]: Hide_am_store_utm || Hide_web_store_utm,
    [CATALOG_TYPES.AO]: Hide_ao_store_utm || Hide_web_store_utm,
  }[catalogType];
  const retail_url = {
    [CATALOG_TYPES.CATALOG]: Retail_store_web,
    [CATALOG_TYPES.APP]: Retail_store_app || Retail_store_web,
    [CATALOG_TYPES.AM]: Retail_store_am || Retail_store_web,
    [CATALOG_TYPES.AO]: Retail_store_ao || Retail_store_web,
  }[catalogType];

  const organizator = data?.attributes?.Sale_card?.Organizator

  const Organizator = {
    [CATALOG_TYPES.CATALOG]: organizator,
    [CATALOG_TYPES.APP]: data?.attributes?.Sale_card?.Organizator_app || organizator,
    [CATALOG_TYPES.AM]: data?.attributes?.Sale_card?.Organizator_am || organizator,
    [CATALOG_TYPES.AO]: data?.attributes?.Sale_card?.Organizator_ao || organizator,
  }[catalogType];

  if (data) {
    const newSaleCard = {...data.attributes.Sale_card, Organizator}
    delete newSaleCard.Organizator_app;
    delete newSaleCard.Organizator_ao;
    delete newSaleCard.Organizator_am;
      selectedData = {
      id: data.id,
      attributes: {
        ...data.attributes,
        Sale_card: {
          ...newSaleCard,
          Web_store: im_url,
          Web_store_title: im_title,
          Hide_web_store_utm: im_utm_hide,
          Retail_store_web: retail_url,
        },
      },
    };
    return selectedData;
  } else {
    return null;
  }
});
